.sideBtnLeft,
.sideBtnRight {
  position: relative;
}
.sideBtnLeft img {
  width: 140px;
}
.sideBtnRight img {
  width: 140px;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background-color: white;
}

.bottom {
  display: flex;
  height: 100px;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  background-color: rgb(255 255 255 / 15%);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  backdrop-filter: blur(20px);
}
.bottomWrapper {
  max-width: 768px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
}
.logo {
  width: 150px;
  height: 100%;
}

.shopBtn:hover {
  color: #f89d9d;
  transition: all ease 0.5s;
}

.shopBtn {
  background: #ba9751;
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
  width: 120px;
  margin-bottom: 20px;
  text-align: center;
}

.main,
.main-special {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 768px;
  margin: 0 auto;
}
.main img:last-child {
  margin-bottom: 100px;
}
.floatBtn {
  position: fixed;
  right: -5px;
  bottom: 130px;
  width: 185px;
  height: auto;
}
